import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import theme from "./theme";
import { ThemeProvider, CssBaseline } from "@mui/material";

import LandingPage from "./components/pages/landing";
import ThumbGeniePublic from "./components/pages/thumbGenie";
import ThumbGenieApp from "./components/screens/thumb-genie";
import PrivacyPolicy from "./components/pages/privacyPolicy";
import CampaignGenie from "./components/pages/campaignGenie";
import CampaignGenieApp from "./components/screens/campaign-genie";
import CampaignDetailsScreen from "./components/screens/campaign-genie/campaignDetails";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/thumb-genie" element={<ThumbGeniePublic />} />
          <Route path="/campaign-genie" element={<CampaignGenie />} />
          <Route path="/app/thumb-genie/*" element={<ThumbGenieApp />} />
          <Route path="/app/campaign-genie/*" element={<CampaignGenieApp />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
