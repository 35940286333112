import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../utils/customButton";
import { IconCalendar, IconBrandYoutube } from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";

export default function LandingPage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-8 font-custom"
      style={{
        backgroundColor: "#0B0B0F",
        backgroundImage: "url(/images/background-pattern.png)",
        backgroundPosition: "top center",
      }}
    >
      <header className="w-full max-w-5xl flex justify-between items-center px-8 py-4 mx-auto">
        <img
          src="/images/logo.svg"
          alt="YouGenie Logo"
          className="h-8 cursor-pointer"
          onClick={handleLogoClick}
        />
      </header>
      <main className="text-center mt-8 max-w-5xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-6 md:text-6xl">YouGenie</h1>
        <p className="mb-6 text-gray-300">
          Discover our AI-powered suite of products designed to supercharge your
          YouTube channel.
        </p>
        <CustomButton
          variant="contained"
          startIcon={<IconCalendar size={20} />}
          onClick={() =>
            window.open(
              "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
              "_blank"
            )
          }
          sx={{
            backgroundColor: theme.palette.primary.main,
            "&:hover": { backgroundColor: theme.palette.primary.dark },
            marginBottom: "1rem",
          }}
        >
          Book a Demo
        </CustomButton>
        <div className="mt-4 mb-8">
          <a
            href="https://youtu.be/J9Nps0kcTyI"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white transition-colors duration-300 flex items-center justify-center"
          >
            <IconBrandYoutube size={20} className="mr-2" />
            Or watch our 17-minute demo now
          </a>
        </div>
        <div className="flex flex-col md:flex-row justify-center mt-12 space-y-8 md:space-y-0 md:space-x-8">
          <div className="bg-[#1A1A1A] rounded-lg p-6 flex flex-col items-center max-w-md">
            <img
              src="/images/thumb-genie.png"
              alt="ThumbGenie"
              className="m-2"
            />
            <h2 className="text-2xl font-bold mb-2">ThumbGenie</h2>
            <p className="text-gray-300 mb-4">
              Create stunning thumbnails for your YouTube videos with our
              AI-powered Thumbnail Generator.
            </p>
            <CustomButton
              variant="contained"
              onClick={() => navigate("/thumb-genie")}
              sx={{
                backgroundColor: theme.palette.primary.main,
                "&:hover": { backgroundColor: theme.palette.primary.dark },
              }}
            >
              Explore ThumbGenie
            </CustomButton>
          </div>
          <div className="bg-[#1A1A1A] rounded-lg p-6 flex flex-col items-center max-w-md">
            <img
              src="/images/campaign-genie.png"
              alt="CampaignGenie"
              className="m-2"
            />
            <h2 className="text-2xl font-bold mb-2">CampaignGenie</h2>
            <p className="text-gray-300 mb-4">
              Supercharge your YouTube ad campaigns with our intelligent
              Campaign Manager.
            </p>
            <CustomButton
              variant="contained"
              onClick={() => navigate("/campaign-genie")}
              sx={{
                backgroundColor: theme.palette.primary.main,
                "&:hover": { backgroundColor: theme.palette.primary.dark },
              }}
            >
              Explore CampaignGenie
            </CustomButton>
          </div>
        </div>
      </main>
      <footer className="w-full max-w-5xl mx-auto mt-16 py-4 text-center">
        <div className="flex justify-center space-x-4">
          <a href="/privacy" className="text-gray-300 hover:text-white">
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  );
}
