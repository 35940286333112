import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/userContext";
import {
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import CustomButton from "../../utils/customButton";
import ChannelDb from "../../../db/channelDb";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../../firebase";
import moment from "moment";
import {
  IconBrandYoutube,
  IconVideo,
  IconX,
  IconPinnedFilled,
  IconChartBar,
  IconClock,
  IconSearch,
} from "@tabler/icons-react";
import { Tooltip as MUITooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Campaign } from "../../../models/campaign";
import CampaignDb from "../../../db/campaignDb";
import { Channel } from "../../../models/channel";
import { youtube_v3 } from "googleapis";
import Functions from "../../../callable/functions";
import { trackEvent } from "../../../utils/analytics";

const CampaignsScreen: React.FC = () => {
  const user = useContext(UserContext);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [campaignName, setCampaignName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [ctaLink, setCtaLink] = useState<string>("");
  const [mode, setMode] = useState<string>("top");
  const [videoCount, setVideoCount] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState<youtube_v3.Schema$Video[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [activeStep, setActiveStep] = useState(0);
  const [selectedVideos, setSelectedVideos] = useState<
    youtube_v3.Schema$Video[]
  >([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    fetchChannels();
    fetchCampaigns();
  }, [user]);

  const fetchChannels = async () => {
    if (user) {
      const channelDb = new ChannelDb();
      const querySnapshot = await getDocs(
        channelDb.collection({ userId: user.id, channelId: "" })
      );
      const channelsData = querySnapshot.docs
        .map((doc) => doc.data() as Channel)
        .filter((channel) => channel.mine); // Filter only user's channels
      setChannels(channelsData);

      // Pre-select the first channel that belongs to the user
      if (channelsData.length > 0) {
        setSelectedChannel(channelsData[0].id);
      }
    }
  };

  const fetchCampaigns = async () => {
    if (user) {
      setLoading(true);
      try {
        const campaignsRef = collection(db, `users/${user.id}/campaigns`);
        const campaignsQuery = query(
          campaignsRef,
          orderBy("startDate", "desc")
        );
        const querySnapshot = await getDocs(campaignsQuery);
        const campaignsData = querySnapshot.docs.map((doc) =>
          Campaign.fromFirestore(doc)
        );
        setCampaigns(campaignsData);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const hasActiveCampaign = campaigns.some((campaign) => !campaign.endDate);

  const generateFinalLink = (campaignName: string, baseLink: string) => {
    const slug = campaignName
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
    const utmParams = new URLSearchParams({
      utm_source: "yougenie",
      utm_medium: "youtube",
      utm_campaign: slug,
    });
    return `${baseLink}${
      baseLink.includes("?") ? "&" : "?"
    }${utmParams.toString()}`;
  };

  const handleSearch = async () => {
    if (user) {
      setIsSearching(true);
      try {
        const searchResult = await new Functions().searchVideos({
          userId: user.id,
          channelId: selectedChannel,
          mode,
          searchTerm: mode === "search" ? searchTerm : undefined,
          videoCount,
        });
        setSearchResults(searchResult.data.videos);
        setSelectedVideos(searchResult.data.videos); // Select all videos by default
      } catch (error) {
        console.error("Error searching videos:", error);
      } finally {
        setIsSearching(false);
      }
    }
  };

  const handleNext = () => {
    if (activeStep === 1 && selectedVideos.length === 0) {
      alert("Please select at least one video before proceeding.");
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCreateCampaign = async () => {
    if (!user || !selectedChannel || !campaignName || !message || !ctaLink)
      return;
    if (hasActiveCampaign) {
      alert("You can't create a new campaign while there's an active one.");
      return;
    }
    if (selectedVideos.length === 0) {
      alert("Please select at least one video for the campaign.");
      return;
    }

    const finalLink = generateFinalLink(campaignName, ctaLink);

    setLoading(true);
    try {
      await addDoc(
        new CampaignDb().collection({
          userId: user.id,
          campaignId: "",
        }),
        new Campaign({
          id: "",
          name: campaignName,
          channelId: selectedChannel,
          message,
          ctaLink: finalLink,
          videoIds: selectedVideos.map((video) => video.id as string),
          videos: [],
          startDate: moment(),
          endDate: null,
          mode,
          videoCount,
          searchTerm: mode === "search" ? searchTerm : undefined,
        })
      );

      fetchCampaigns();
      setCreateDialogOpen(false);
      resetForm();
      trackEvent("campaign_created");
    } catch (error) {
      console.error("Error creating campaign:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEndCampaign = async (campaignId: string) => {
    if (!user) return;

    setLoading(true);
    try {
      const campaignRef = doc(db, `users/${user.id}/campaigns/${campaignId}`);
      await updateDoc(campaignRef, { endDate: moment().toDate() });
      fetchCampaigns();
    } catch (error) {
      console.error("Error ending campaign:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setCampaignName("");
    setMessage("");
    setCtaLink("");
    setMode("top");
    setVideoCount(5);
    setSearchTerm("");
    setActiveStep(0);
    setSearchResults([]);
    setSelectedVideos([]);
  };

  const getUnpinnedVideosCount = (campaign: Campaign) => {
    return campaign.videos.filter((video) => !video.updatedPinnedCommentId)
      .length;
  };

  const handleCampaignClick = (campaignId: string) => {
    navigate(`/app/campaign-genie/${campaignId}`);
  };

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-16 md:py-8 font-custom">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-base font-custom font-bold">Campaign Manager</h4>
        <CustomButton
          onClick={() => setCreateDialogOpen(true)}
          disabled={hasActiveCampaign}
        >
          Create
        </CustomButton>
      </div>
      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 2,
          marginBottom: 4,
        }}
      />

      {loading ? (
        <div className="flex justify-center items-center mt-8">
          <CircularProgress color="inherit" size={20} />
        </div>
      ) : campaigns.length === 0 ? (
        <p className="text-sm text-gray-400 mt-8">No campaigns found.</p>
      ) : (
        <div className="space-y-2">
          <div className="grid grid-cols-12 gap-4 text-xs text-gray-400 mb-2 px-4">
            <div className="col-span-5">Name</div>
            <div className="col-span-1">Mode</div>
            <div className="col-span-1">Videos</div>
            <div className="col-span-1">Unpinned</div>
            <div className="col-span-1">Start</div>
            <div className="col-span-1">End</div>
            <div className="col-span-2">Status</div>
          </div>
          {campaigns.map((campaign: Campaign, index) => (
            <React.Fragment key={campaign.id}>
              <div
                className="grid grid-cols-12 gap-4 py-3 px-4 hover:bg-gray-800 rounded-lg transition-colors duration-200 items-center cursor-pointer"
                onClick={() => handleCampaignClick(campaign.id!)}
              >
                <div className="col-span-5 flex items-center space-x-3">
                  {channels.find((c) => c.id === campaign.channelId)?.thumbnails
                    ?.default?.url ? (
                    <img
                      src={
                        channels.find((c) => c.id === campaign.channelId)
                          ?.thumbnails?.default?.url || undefined
                      }
                      alt={
                        channels.find((c) => c.id === campaign.channelId)
                          ?.title || ""
                      }
                      className="w-6 h-6 rounded-full"
                    />
                  ) : (
                    <IconBrandYoutube size={20} className="text-[#FF0000]" />
                  )}
                  <MUITooltip
                    title={
                      <div className="p-2">
                        <p>
                          <strong>Message:</strong> {campaign.message}
                        </p>
                        <br />
                        <p>
                          <strong>CTA Link:</strong> {campaign.ctaLink}
                        </p>
                      </div>
                    }
                    arrow
                  >
                    <span className="text-sm truncate cursor-pointer">
                      {campaign.name}
                    </span>
                  </MUITooltip>
                </div>
                <div className="col-span-1 text-sm">
                  <MUITooltip
                    title={
                      campaign.mode === "top"
                        ? "Top Videos"
                        : campaign.mode === "last"
                        ? "Latest Videos"
                        : "Search Videos"
                    }
                  >
                    {campaign.mode === "top" ? (
                      <IconChartBar size={16} className="text-gray-400" />
                    ) : campaign.mode === "last" ? (
                      <IconClock size={16} className="text-gray-400" />
                    ) : (
                      <IconSearch size={16} className="text-gray-400" />
                    )}
                  </MUITooltip>
                </div>
                <div className="col-span-1 text-sm flex items-center">
                  <IconVideo size={16} className="mr-1 text-gray-400" />
                  {campaign.videos.length}
                </div>
                <div className="col-span-1 text-sm flex items-center">
                  <IconPinnedFilled size={16} className="mr-1 text-gray-400" />
                  <MUITooltip
                    title={
                      <div className="p-2">
                        <p>
                          <strong>Videos without pinned comments:</strong>
                        </p>
                        <ul>
                          {campaign.videos
                            .filter((video) => !video.updatedPinnedCommentId)
                            .map((video, index) => (
                              <li key={index}>
                                <a
                                  href={`https://www.youtube.com/watch?v=${video.videoId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-400 hover:underline"
                                >
                                  https://www.youtube.com/watch?v=
                                  {video.videoId}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    }
                    arrow
                  >
                    <span className="cursor-pointer">
                      {getUnpinnedVideosCount(campaign)}
                    </span>
                  </MUITooltip>
                </div>
                <div className="col-span-1 text-sm">
                  {moment(campaign.startDate).format("DD MMM YY")}
                </div>
                <div className="col-span-1 text-sm">
                  {campaign.endDate
                    ? moment(campaign.endDate).format("DD MMM YY")
                    : "-"}
                </div>
                <div className="col-span-2 text-sm flex items-center justify-between">
                  <span
                    className={`font-bold ${
                      campaign.endDate ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {campaign.endDate ? "Stopped" : "Active"}
                  </span>
                  {!campaign.endDate && (
                    <MUITooltip title="End Campaign">
                      <IconX
                        size={18}
                        className="text-gray-400 hover:text-red-500 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEndCampaign(campaign.id!);
                        }}
                      />
                    </MUITooltip>
                  )}
                </div>
              </div>
              {index < campaigns.length - 1 && (
                <Divider sx={{ borderColor: "#282828", margin: "8px 0" }} />
              )}
            </React.Fragment>
          ))}
        </div>
      )}

      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "#0B0B0F",
            color: "white",
            fontFamily: "'YouTubeSansRegular', sans-serif",
            minWidth: "600px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: "'YouTubeSansRegular', sans-serif",
            fontSize: "1rem",
            paddingBottom: 2,
          }}
        >
          Create New Campaign
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {["Campaign Details", "Video Selection", "Review & Create"].map(
              (label, index) => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      style: {
                        color: activeStep >= index ? "#FF0000" : undefined,
                      },
                    }}
                  >
                    <span
                      className={`text-gray-300 font-custom ${
                        activeStep === index ? "font-bold" : ""
                      }`}
                    >
                      {label}
                    </span>
                  </StepLabel>
                </Step>
              )
            )}
          </Stepper>

          <div className="mt-8">
            {activeStep === 0 && (
              <div className="space-y-4">
                <FormControl fullWidth>
                  <InputLabel
                    id="channel-select-label"
                    sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    Channel
                  </InputLabel>
                  <Select
                    labelId="channel-select-label"
                    value={selectedChannel}
                    onChange={(e) => setSelectedChannel(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: "#1E1E1E",
                          color: "white",
                          borderRadius: "8px",
                        },
                      },
                    }}
                    sx={{
                      color: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#282828",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#484848",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FF0000",
                      },
                      "& .MuiSelect-icon": {
                        color: "white",
                      },
                    }}
                  >
                    {channels.map((channel) => (
                      <MenuItem key={channel.id} value={channel.id}>
                        {channel.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Campaign Name"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  rows={4}
                />
                <TextField
                  fullWidth
                  label="CTA Link"
                  value={ctaLink}
                  onChange={(e) => setCtaLink(e.target.value)}
                />
              </div>
            )}
            {activeStep === 1 && (
              <div className="space-y-4">
                <FormControl fullWidth>
                  <InputLabel
                    id="mode-select-label"
                    sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    Mode
                  </InputLabel>
                  <Select
                    labelId="mode-select-label"
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: "#1E1E1E",
                          color: "white",
                          borderRadius: "8px",
                        },
                      },
                    }}
                    sx={{
                      color: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#282828",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#484848",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FF0000",
                      },
                      "& .MuiSelect-icon": {
                        color: "white",
                      },
                    }}
                  >
                    <MenuItem value="top">Top Videos</MenuItem>
                    <MenuItem value="last">Latest Videos</MenuItem>
                    <MenuItem value="search">Search</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  type="number"
                  label="Video Count"
                  value={videoCount}
                  onChange={(e) =>
                    setVideoCount(Math.min(Number(e.target.value), 50))
                  }
                  InputProps={{ inputProps: { min: 1, max: 50 } }}
                />
                {mode === "search" && (
                  <TextField
                    fullWidth
                    label="Search Term"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                )}
                <CustomButton onClick={handleSearch} disabled={isSearching}>
                  {isSearching ? "Searching..." : "Search"}
                </CustomButton>
                {searchResults.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-lg font-bold mb-2">Search Results</h3>
                    <div className="max-h-60 overflow-y-auto">
                      {searchResults.map((video) => (
                        <MUITooltip
                          key={video.id}
                          title={
                            <img
                              src={video.snippet?.thumbnails?.medium?.url || ""}
                              className="w-40 rounded"
                            />
                          }
                          arrow
                        >
                          <div className="flex items-center space-x-2 mb-2 p-2 hover:bg-gray-800 rounded">
                            <input
                              type="checkbox"
                              checked={selectedVideos.some(
                                (v) => v.id === video.id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedVideos([...selectedVideos, video]);
                                } else {
                                  setSelectedVideos(
                                    selectedVideos.filter(
                                      (v) => v.id !== video.id
                                    )
                                  );
                                }
                              }}
                            />
                            <span className="truncate">
                              {video.snippet?.title}
                            </span>
                          </div>
                        </MUITooltip>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {activeStep === 2 && (
              <div className="space-y-6">
                <h3 className="text-base font-bold mb-4">Campaign Review</h3>
                <div className="bg-gray-800 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="text-base font-semibold">{campaignName}</h4>
                    <div className="text-sm text-gray-400">
                      <span className="mr-3">
                        Mode:{" "}
                        <span className="text-white capitalize">{mode}</span>
                      </span>
                      <span>
                        Videos:{" "}
                        <span className="text-white">
                          {selectedVideos.length}
                        </span>
                      </span>
                    </div>
                  </div>
                  <p className="text-gray-300 mb-4">{message}</p>
                  <div>
                    <h5 className="font-semibold mb-1">CTA Link:</h5>
                    <a
                      href={generateFinalLink(campaignName, ctaLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:underline break-all"
                    >
                      {generateFinalLink(campaignName, ctaLink)}
                    </a>
                  </div>
                </div>
                {mode === "search" && (
                  <div className="bg-gray-800 p-4 rounded-lg">
                    <h5 className="font-semibold mb-2">Search Term</h5>
                    <p>{searchTerm}</p>
                  </div>
                )}
                <div className="bg-gray-800 p-4 rounded-lg">
                  <h5 className="font-semibold mb-2">Selected Videos</h5>
                  <ul className="list-disc list-inside">
                    {selectedVideos.slice(0, 5).map((video) => (
                      <li key={video.id} className="truncate">
                        {video.snippet?.title}
                      </li>
                    ))}
                    {selectedVideos.length > 5 && (
                      <li className="text-gray-400">
                        And {selectedVideos.length - 5} more...
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setCreateDialogOpen(false)}>
            Cancel
          </CustomButton>
          {activeStep > 0 && (
            <CustomButton onClick={handleBack}>Back</CustomButton>
          )}
          {activeStep < 2 ? (
            <CustomButton onClick={handleNext} disabled={isSearching}>
              Next
            </CustomButton>
          ) : (
            <CustomButton onClick={handleCreateCampaign} disabled={loading}>
              {loading ? "Creating..." : "Create Campaign"}
            </CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CampaignsScreen;
