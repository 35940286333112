import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconMenu, IconX, IconHome, IconLogout } from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";

export default function PopMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleHome = () => {
    navigate("/app/thumb-genie");
    setIsOpen(false);
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate("/thumb-genie");
    setIsOpen(false);
  };

  return (
    <div className="md:hidden relative" style={{ backgroundColor: "#0B0B0F" }}>
      <button onClick={toggleMenu} className="text-white p-4">
        {isOpen ? <IconX size={20} /> : <IconMenu size={20} />}
      </button>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex flex-col items-center py-6 space-y-8 z-50"
          style={{ backgroundColor: "#0B0B0F" }}
        >
          <button
            onClick={handleHome}
            className="flex items-center justify-center"
          >
            <IconHome size={24} className="text-white" />
            <span className="mt-2 text-sm">Home</span>
          </button>
          <button
            onClick={handleLogout}
            className="flex items-center justify-center"
          >
            <IconLogout size={24} className="text-white" />
            <span className="mt-2 text-sm">Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
