import React from "react";
import { useNavigate } from "react-router-dom";
import { IconHome, IconLogout } from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";

export default function SideBar() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/app/campaign-genie");
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate("/campaign-genie");
  };

  return (
    <div className="bg-[#0B0B0F] h-screen w-20 flex flex-col items-center py-6 space-y-6 border-r border-[#282828] fixed top-0 left-0">
      <button onClick={handleHome}>
        <IconHome size={24} strokeWidth={1} className="text-[#FFFFFF] mb-6" />
      </button>
      <div className="flex-grow" />
      <button onClick={handleLogout}>
        <IconLogout size={24} strokeWidth={1} className="text-[#FFFFFF]" />
      </button>
    </div>
  );
}
